import { API_ROUTES } from 'data/ApiRoutes';
import { GET } from 'data/services/api';
import { AxiosResponse } from 'axios';
import { buildApiPath } from 'utils';

export const getConversations = async (userId: string) => {
  try {
    const data: AxiosResponse<any> = await GET(
      API_ROUTES.getConversations(userId),
    );
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const getAdminDashboardStats = async (companyId: string) => {
  try {
    const data: AxiosResponse<any> = await GET(
      API_ROUTES.adminDashboardStats(companyId),
    );
    return data.data;
  } catch (e) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
