import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'components/Select';
import {
  Box,
  Divider,
  Grid,
  Stack,
  Switch,
  Tooltip,
  styled,
} from '@mui/material';
import TextField from 'components/TextField';
import CheckboxSingle from 'components/CheckboxSingle';
import ActiveIcon from '../image/activeIcon.svg';
import OnActiveIcon from '../image/onActiveIcon.svg';
import Button from 'components/Button';
import ModalCustom from 'components/Modal';
import { useForm } from 'react-hook-form';
import {
  EducationLevel,
  EmploymentStatusEnum,
  Ethnicity,
  IncomeBrackets,
  TypeOfEmailEnum,
} from '../../pages/new-user/interfaces';
import { GENDERS, STATES } from 'data/constants/static';
import { CompanyOverview, CompanyInfo } from '@data/models/company.model';
import TextFieldWithMask from 'components/TextFieldWithMask';
import {
  createAdminUser,
  deleteUser,
  getSignedPutUrl,
  updateAdminUser,
  uploadLogoCompanyToAWS,
} from 'data/api/user.api';
import { UserInfo, UserOverview } from '@data/models/user.model';
import { SignedPutUrl } from '@data/models/signed-put-rl.model';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUser } from 'store/userSlide';
import { DASHBOARD_ROUTES } from 'Routes';
import { selectCompany } from 'store/companySlide';
import moment from 'moment';
import { SearchBox } from 'pages/company/components/SearchBox';
import { SearchUserNew } from '../search-user/SearchUserNew';
import TextFieldNumber from 'components/TextFieldNumber';
import DatePicker from 'components/DatePicker';
import { TypeCreateBy } from 'pages/user/enum/typeCreateBy.enum';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LaunchIcon from '@mui/icons-material/Launch';
import { saveNavigateLinkHeader } from 'store/navigateSlide';

const MaterialUISwitch = styled(Switch)(() => ({
  width: 52,
  padding: 0,
  height: 32,
  backgroundColor: '#213B54',
  '& .MuiSwitch-switchBase': {
    margin: 2,
    marginLeft: 0.1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(${OnActiveIcon})`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#213B54',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#213B54',
    width: 24,
    height: 24,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${ActiveIcon})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'white',
    borderRadius: 20 / 2,
  },
}));

const CustomDivider = ({ text }: { text: string }) => {
  return (
    <Divider
      textAlign="left"
      sx={{
        ' :before': {
          width: '0 !important',
        },
        ' :after': {
          width: '100% !important',
        },
        '&::before, &::after': {
          borderColor: '#213B54',
        },
        marginY: '33px',
        color: '#213B54',
        fontWeight: 700,
        fontSize: '14px',
        '& .MuiDivider-wrapper': {
          paddingLeft: '0px',
        },
      }}
    >
      {text}
    </Divider>
  );
};

const DetailFormUser = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const userInfo: UserInfo = useSelector(selectUser);
  const companyInfo: CompanyInfo = useSelector(selectCompany);
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState({ active: false, modalActive: false });
  const [isAdmin, setIsAdmin] = useState(false);
  const [value, setValue] = useState(false);
  const [file, setFile] = useState<File>();
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(
    userInfo.photoUrl,
  );

  const [company, setCompany] = React.useState<
    CompanyOverview | null | undefined
  >();
  const [referredByCompany, setReferredByCompany] = React.useState<
    CompanyOverview | null | undefined
  >();
  const [referredByUser, setReferredByUser] = React.useState<
    UserOverview | null | undefined
  >();

  const navigate = useNavigate();

  const { handleSubmit, control, reset, clearErrors, watch } = useForm({
    mode: 'onChange',
  });

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleAllValid = async (values: UserInfo) => {
    try {
      const data = {
        ...values,
        companyId: company?.companyId,
        referredByUserId: referredByUser?.userId,
        referralCompanyId: referredByCompany?.companyId,
        isActive: active?.active || false,
        phone: values?.phone?.replaceAll(/\D/g, ''),
        isAdmin: isAdmin,
        typeCreateBy: TypeCreateBy.AdminMember,
      };
      if (!active.active) {
        setValue(true);
        setActive({
          active: active.active,
          modalActive: true,
        });
      }
      if (active.active) {
        if (!user_id) {
          await createAdminUser(data)
            .then((data) => {
              if (data && file) {
                uploadLogoForCreateUserToAws(data.userId);
              }
              toast.success('Create user successfully!');
              navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
            })
            .catch((error) => {
              toast.error(error);
            });
        } else {
          await updateAdminUser(user_id, data)
            .then(() => {
              toast.success('Update user successfully!');
              navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
            })
            .catch((error) => {
              toast.error(error);
              return;
            });
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUploadLogo = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      const fileCurrent = files[0];
      setPhotoUrl(URL.createObjectURL(fileCurrent));
    }
    if (files?.length) {
      const fileCurrent = files[0];
      const ACCEPT_TYPES = ['image/png', 'image/jpeg'];
      if (!ACCEPT_TYPES.includes(fileCurrent.type)) {
        toast.error(
          'This file type is not supported. The following file types are supported: jpeg, png!',
        );
        return;
      }

      const FILE_MAX_SIZES = 5;
      const isLimitSize = fileCurrent.size / 1024 / 1024 < FILE_MAX_SIZES;
      if (!isLimitSize) {
        toast.error(
          `The file size is too large. The maximum file size is ${FILE_MAX_SIZES}MB.`,
        );
        return;
      }

      try {
        if (user_id) {
          setFile(fileCurrent);
          const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
            user_id,
            fileCurrent.type,
          );
          await uploadLogoCompanyToAWS(dataSignedPut.signedPutUrl, fileCurrent);
          setPhotoUrl(dataSignedPut.userPhotoUrl);
          toast.success('Upload success!');
        } else {
          setPhotoUrl(URL.createObjectURL(fileCurrent));
          setFile(fileCurrent);
        }
      } catch (error) {
        console.error(error);
        toast.error('Upload fail!');
      }
    }
  };

  const uploadLogoForCreateUserToAws = async (userId?: string) => {
    if (userId && file) {
      const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
        userId,
        file.type,
      );
      await uploadLogoCompanyToAWS(dataSignedPut.signedPutUrl, file);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (user_id) {
        await deleteUser(user_id).then(() => {
          toast.success('Delete user successfully!');
          navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
        });
      }
    } catch (error) {
      toast.error('error');
    }
  };

  const handleSubmitDelete = () => {
    setModal(false);
    handleDeleteUser();
  };

  const handleClose = () => {
    setModal(false);
    setActive({
      active: true,
      modalActive: false,
    });
  };

  const handleSubmitActive = async () => {
    setActive({
      active: false,
      modalActive: false,
    });
    if (value) {
      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        isEmployee,
        zipCode,
        email,
        emailType,
        personalEmail,
        personalEmailType,
        phone,
        state,
        employmentStatus,
        dateOfBirth,
        gender,
        incomeBrackets,
        ethnicity,
        educationalLevel,
        photoUrl,
        isSendWelcomeEmail,
        subscribeProductEmails,
        subscribeProductTexts,
        subscribeMarketingEmails,
      } = watch();

      const data = {
        firstName: firstName,
        lastName: lastName,
        address1: address1,
        address2: address2,
        city: city,
        isActive: false,
        isEmployee: isEmployee,
        isAdmin: isAdmin,
        companyId: company?.companyId,
        zipCode: zipCode,
        email: email,
        emailType: emailType,
        personalEmail: personalEmail,
        personalEmailType: personalEmailType,
        phone: phone?.replaceAll(/\D/g, ''),
        referredByUserId: referredByUser?.userId,
        referralCompanyId: referredByCompany?.companyId,
        state: state,
        employmentStatus: employmentStatus,
        dateOfBirth: dateOfBirth,
        gender: gender,
        incomeBrackets: incomeBrackets,
        ethnicity: ethnicity,
        educationalLevel: educationalLevel,
        photoUrl: photoUrl,
        isSendWelcomeEmail: isSendWelcomeEmail,
        typeCreateBy: TypeCreateBy.AdminMember,
        subscribeProductEmails,
        subscribeProductTexts,
        subscribeMarketingEmails,
      };
      if (!user_id) {
        await createAdminUser(data)
          .then((data) => {
            if (data && file) {
              uploadLogoForCreateUserToAws(data.userId);
            }
            toast.success('Create user successfully!');
            navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
          })
          .catch((error) => {
            toast.error(error);
          });
      } else {
        await updateAdminUser(user_id, data)
          .then(() => {
            toast.success('Update user successfully!');
            navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
          })
          .catch((error) => {
            toast.error(error);
            return;
          });
      }
    }
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  const resetForm = () => {
    reset({
      employeeId: user_id ? userInfo?.companyId || '' : '',
      firstName: user_id ? userInfo?.firstName || '' : '',
      lastName: user_id ? userInfo?.lastName || '' : '',
      address1: user_id ? userInfo?.address1 || '' : '',
      address2: user_id ? userInfo?.address2 || '' : '',
      city: user_id ? userInfo?.city || '' : '',
      isActive: user_id ? userInfo?.isActive || false : false,
      isEmployee: user_id ? userInfo?.isEmployee || false : false,
      isAdmin: user_id ? userInfo?.isAdmin || false : false,
      companyId: user_id ? userInfo?.companyId || '' : '',
      zipCode: user_id ? userInfo?.zipCode || '' : '',
      email: user_id ? userInfo?.email || '' : '',
      emailType: user_id
        ? userInfo?.emailType || TypeOfEmailEnum.Work
        : TypeOfEmailEnum.Work,
      personalEmail: user_id ? userInfo?.personalEmail || '' : '',
      personalEmailType: user_id
        ? userInfo?.personalEmailType || TypeOfEmailEnum.Personal
        : TypeOfEmailEnum.Personal,
      phone: user_id ? userInfo?.phone || '' : '',
      referralCompanyId: user_id ? userInfo?.referralCompanyId || '' : '',
      referredByUserId: user_id ? userInfo?.referredByUserId || '' : '',
      state: user_id ? userInfo?.state || '' : '',
      employmentStatus: user_id ? userInfo?.employmentStatus || '' : '',
      dateOfBirth: user_id ? userInfo?.dateOfBirth || null : null,
      gender: user_id ? userInfo?.gender || '' : '',
      incomeBrackets: user_id ? userInfo?.incomeBrackets || '' : '',
      ethnicity: user_id ? userInfo?.ethnicity || '' : '',
      educationalLevel: user_id ? userInfo?.educationalLevel || '' : '',
      photoUrl: user_id ? userInfo?.photoUrl || '' : '',
      isSendWelcomeEmail: false,
      activationLink: user_id ? userInfo?.activationLink || 'No any link' : '',

      subscribeProductEmails: user_id ? userInfo?.subscribeProductEmails : true,
      subscribeProductTexts: user_id ? userInfo?.subscribeProductTexts : true,
      subscribeMarketingEmails: user_id
        ? userInfo?.subscribeMarketingEmails
        : true,
    });
    clearErrors();
  };

  React.useEffect(() => {
    resetForm();
    setPhotoUrl(userInfo.photoUrl);
    setActive({
      active: userInfo?.isActive || false,
      modalActive: false,
    });
    setIsAdmin(userInfo?.isAdmin || false);

    if (userInfo && userInfo.userId) {
      setCompany({
        companyId: user_id ? userInfo?.companyId || '' : '',
        rank: 0,
        name: userInfo.companyName as string,
        employeeCount: userInfo.employeeCount as number,
        createdDate: '',
      });

      if (userInfo?.referralCompanyId) {
        setReferredByCompany({
          companyId: user_id ? userInfo?.referralCompanyId || '' : '',
          rank: 0,
          name: userInfo?.referralCompanyName || '',
          employeeCount: 0,
          createdDate: '',
        });
      }

      if (userInfo?.referredByUserId) {
        setReferredByUser({
          userId: userInfo?.referredByUserId || '',
          rank: 0,
          companyName: '',
          lastLoginDate: '',
          createdDate: '',
          email: '',
          name:
            userInfo?.referredByFirstName + ' ' + userInfo?.referredByLastName,
          firstName: userInfo?.referredByFirstName || '',
          lastName: userInfo?.referredByLastName || '',
        });
      }
    } else {
      setCompany({
        companyId: companyInfo.companyId as string,
        rank: 0,
        name: companyInfo.name as string,
        employeeCount: companyInfo.employeeCount as number,
        createdDate: '',
      });
    }
  }, [companyInfo, userInfo]);

  return (
    <>
      <form
        onSubmit={handleSubmit(handleAllValid)}
        style={{ maxWidth: '923px' }}
      >
        <Grid
          container
          sx={{
            alignItems: 'left',
            justifyContent: 'left',
            maxWidth: { xs: '100%', sm: '800px' },
            marginTop: '12px',
          }}
          rowSpacing={2}
          columnSpacing={4}
        >
          {!user_id ? (
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '18px',
                color: '#213B54',
              }}
            >
              <CheckboxSingle
                control={control}
                name="isSendWelcomeEmail"
                label="Send the user welcome email."
              />
            </Grid>
          ) : !userInfo.activationLink ? (
            <></>
          ) : (
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '18px',
                color: '#213B54',
              }}
            >
              <CheckboxSingle
                control={control}
                name="isSendWelcomeEmail"
                label="Send the user welcome email."
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={!user_id ? 4 : !userInfo.activationLink ? 12 : 4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={{
                order: { xs: 3, md: 1 },
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Button
                sx={{
                  border: '1px solid #213B54',
                  color: '#213B54',
                  fontWeight: 700,
                  fontSize: '14px',
                  display: user_id ? '' : 'none',
                  lineHeight: '20px',
                  width: '104px',
                  height: '40px',
                }}
                onClick={() => {
                  setModal(true);
                }}
              >
                Delete
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: 700,
                  marginLeft: '12px',
                  width: '104px',
                  height: '40px',
                }}
              >
                Save
              </Button>
            </Box>
          </Grid>
          {user_id && userInfo.activationLink && (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '18px',
                color: '#213B54',
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <TextField
                  name="activationLink"
                  label="Activation Link"
                  control={control}
                  disabled
                  placeholder=""
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#79747E !important',
                    },
                    '& .MuiFormLabel-root': {
                      color: '#213B54 !important',
                    },
                  }}
                />
                <Tooltip title={'Copy to clipboard '}>
                  <Button
                    startIcon={
                      <InsertLinkIcon
                        sx={{
                          cursor: 'pointer',
                          rotate: '135deg',
                        }}
                      />
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(
                        userInfo?.activationLink || '',
                      );
                      toast.success('Copied property to clipboard');
                    }}
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      border: '1px solid #213B54 ',
                      width: '135px',
                      height: '40px',
                      borderRadius: '100px',
                      marginLeft: '12px',
                    }}
                  >
                    Copy Link
                  </Button>
                </Tooltip>
              </Stack>
            </Grid>
          )}
        </Grid>

        <CustomDivider text="Basic Info" />

        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{
            justifyContent: 'left',
            maxWidth: { sm: '800px' },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              fontWeight: 500,
              color: '#213B54',
              justifySelf: 'center',
              alignSelf: 'center',
            }}
          >
            <MaterialUISwitch
              sx={{
                border: '2px solid #213B54',
                borderRadius: '100px',
                marginRight: '12px',
              }}
              checked={active.active}
              name="isActive"
              onChange={(e) => {
                setActive({
                  active: e.target.checked,
                  modalActive: false,
                });
              }}
            />
            {`Active`}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              fontWeight: 500,
              color: '#213B54',
              fontSize: '15px',
            }}
          >
            <MaterialUISwitch
              sx={{
                border: '2px solid #213B54',
                borderRadius: '100px',
                marginRight: '12px',
              }}
              onChange={() => {
                setIsAdmin(!isAdmin);
              }}
              checked={isAdmin}
            />
            {`Admin`}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              label="First Name"
              required
              control={control}
              placeholder="First Name"
              rules={{
                validate: (value) => {
                  const regex = /^[a-zA-Z0-9.'\s-]{2,50}$/;
                  if (!value) return true;
                  if (
                    !value?.trim() ||
                    value?.trim()?.length < 2 ||
                    regex.test(value?.trim()) === false
                  )
                    return 'Please enter 2 ~ 50 letters of a-z, A-Z, 0-9, Period, Single quote, Whitespace, or Hyphen';
                  return true;
                },
                required: 'First Name is required.',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            direction={'row'}
            display={'flex'}
            gap={1}
            alignItems={'center'}
          >
            <SearchBox
              label="Company"
              required
              disabled={companyInfo.companyId || userInfo.userId ? true : false}
              setCompany={(company: CompanyOverview) => {
                setCompany({
                  companyId: company.companyId,
                  rank: company.rank,
                  name: company.name,
                  employeeCount: company.employeeCount,
                  createdDate: company.createdDate,
                });
              }}
              defaultValue={
                !company
                  ? undefined
                  : {
                      companyId: company.companyId,
                      rank: company.rank,
                      name: company.name,
                      employeeCount: company.employeeCount,
                      createdDate: company.createdDate,
                    }
              }
            />
            {user_id && (
              <Tooltip title={'Go to company details'}>
                <LaunchIcon
                  sx={{
                    color: '#213B54',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    handleNavigateToDetail(
                      DASHBOARD_ROUTES.COMPANY_OVERVIEW,
                      DASHBOARD_ROUTES.COMPANY_DETAIL + userInfo.companyId,
                    );
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              label="Last Name"
              control={control}
              required
              rules={{
                validate: (value) => {
                  const regex = /^[a-zA-Z0-9.'\s-]{2,50}$/;
                  if (!value) return true;
                  if (
                    !value?.trim() ||
                    value?.trim()?.length < 2 ||
                    regex.test(value?.trim()) === false
                  )
                    return 'Please enter 2 ~ 50 letters of a-z, A-Z, 0-9, Period, Single quote, Whitespace, or Hyphen';
                  return true;
                },
                required: 'Last Name is required.',
              }}
              placeholder="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckboxSingle
              control={control}
              name="isEmployee"
              label="Is Employee"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="email"
              name="email"
              required
              rules={{ required: 'Primary Email is required.' }}
              label="Primary Email"
              control={control}
              placeholder="Type Primary Email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name="emailType"
              label="Primary Email Type"
              control={control}
              showSelected={false}
              data={[
                {
                  value: TypeOfEmailEnum.Work,
                  label: 'Work',
                },
                {
                  value: TypeOfEmailEnum.Personal,
                  label: 'Personal',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldWithMask
              label="Phone"
              placeholder="Type Phone"
              format="(###) ### ####"
              control={control}
              name="phone"
            />
          </Grid>
        </Grid>

        {/* <CustomDivider text="Communication Preferences" /> */}

        {/* <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{
            justifyContent: 'left',
            maxWidth: { sm: '800px' },
          }}
        >
          <Grid item xs={12} sm={6}>
            <CheckboxSingle
              name="subscribeProductEmails"
              label="Receive product emails"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CheckboxSingle
              name="subscribeMarketingEmails"
              label="Receive marketing emails"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CheckboxSingle
              name="subscribeProductTexts"
              label="Receive product texts"
              control={control}
            />
          </Grid>
        </Grid> */}

        <CustomDivider text="Address & Demographics" />

        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{
            justifyContent: 'left',
            maxWidth: { sm: '800px' },
          }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              name="address1"
              label="Address 1"
              control={control}
              placeholder="Address 1"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              name="dateOfBirth"
              label="Date of Birth"
              control={control}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="address2"
              label="Address 2"
              control={control}
              placeholder="Address 1"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              name="gender"
              control={control}
              label="Gender"
              data={GENDERS.map((item) => ({
                value: item,
                label: item,
              }))}
              showSelected={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="city"
              control={control}
              label="City"
              placeholder="City"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              name="ethnicity"
              control={control}
              label="Ethnicity"
              data={Ethnicity.map((item) => ({
                value: item,
                label: item,
              }))}
              showSelected={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name="state"
              label="State"
              control={control}
              data={STATES.filter((state) => {
                return state.name != 'All';
              }).map((item) => ({
                value: item.abbreviation,
                label: item.name,
              }))}
              showSelected={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              name="employmentStatus"
              control={control}
              label="Employment Status"
              data={[
                {
                  value: EmploymentStatusEnum.ACTIVE,
                  label: 'Active',
                },
                {
                  value: EmploymentStatusEnum.PENDING,
                  label: 'Pending',
                },
                {
                  value: EmploymentStatusEnum.INACTIVE,
                  label: 'Inactive',
                },
              ]}
              showSelected={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldNumber
              autoComplete="off"
              name="zipCode"
              label="Zip Code"
              control={control}
              inputProps={{
                maxLength: 5,
              }}
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  if (value?.length !== 5)
                    return 'The US zip code must contain 5 digits.';
                  return true;
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              name="incomeBrackets"
              control={control}
              label="Income"
              data={IncomeBrackets.map((item) => ({
                value: item,
                label: item,
              }))}
              showSelected={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '180px',
                width: '254px',
                color: '#000000',
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '24px',
                marginTop: '-6px',
              }}
            >
              {`Profile Photo`}
              <Button
                component="label"
                sx={{
                  width: '220px',
                  height: '150px !important',
                  color: '#000000 !important',
                  background: '#D9D9D97A !important',
                  border: '2px solid #e9ebef',
                  borderRadius: '4px !important',
                  cursor: 'pointer',
                  margin: '3px 0 0 ',
                  textAlign: 'center',
                  fontWeight: 500,
                }}
              >
                {photoUrl ? (
                  <img
                    src={photoUrl}
                    style={{
                      display: 'block',
                      height: '150px',
                      width: '220px',
                    }}
                  />
                ) : (
                  <>Upload file</>
                )}

                <VisuallyHiddenInput
                  onChange={handleUploadLogo}
                  name="photoUrl"
                  type="file"
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name="educationalLevel"
              control={control}
              label="Educational Level"
              data={EducationLevel.map((item) => ({
                value: item,
                label: item,
              }))}
              showSelected={true}
            />
          </Grid>
        </Grid>

        <CustomDivider text="Additional Info" />

        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          sx={{
            marginBottom: '32px',
            justifyContent: 'left',
            maxWidth: { sm: '800px' },
          }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              type="email"
              name="personalEmail"
              label="Secondary Email"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name="personalEmailType"
              label="Secondary Email Type"
              control={control}
              showSelected={false}
              data={[
                {
                  value: TypeOfEmailEnum.Work,
                  label: 'Work',
                },
                {
                  value: TypeOfEmailEnum.Personal,
                  label: 'Personal',
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SearchBox
              label="Referred By Company"
              setCompany={(company: CompanyOverview) => {
                setReferredByCompany({
                  companyId: company.companyId,
                  rank: company.rank,
                  name: company.name,
                  employeeCount: company.employeeCount,
                  createdDate: company.createdDate,
                });
              }}
              defaultValue={
                !referredByCompany
                  ? undefined
                  : {
                      companyId: referredByCompany.companyId,
                      rank: referredByCompany.rank,
                      name: referredByCompany.name,
                      employeeCount: referredByCompany.employeeCount,
                      createdDate: referredByCompany.createdDate,
                    }
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SearchUserNew
              label="Referred By User"
              setUser={(user: UserOverview) => {
                setReferredByUser({
                  userId: user.userId,
                  rank: user.rank,
                  companyName: user.companyName,
                  lastLoginDate: user.lastLoginDate,
                  createdDate: user.createdDate,
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  name: user.name,
                });
              }}
              defaultValue={
                !referredByUser
                  ? undefined
                  : {
                      userId: referredByUser.userId,
                      rank: referredByUser.rank,
                      name: referredByUser.name,
                      companyName: referredByUser.companyName,
                      lastLoginDate: referredByUser.lastLoginDate,
                      createdDate: referredByUser.createdDate,
                      email: referredByUser.email,
                      firstName: referredByUser.firstName,
                      lastName: referredByUser.lastName,
                    }
              }
            />
          </Grid>
        </Grid>
        {user_id && (
          <>
            <CustomDivider text="User Info" />
            <Grid
              container
              sx={{
                marginBottom: '48px',
                maxWidth: { sm: '800px' },
              }}
            >
              <Grid
                item
                xs={8}
                container
                sx={{
                  borderTop: '1px solid #e9ebef',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: '#f8f9fb',
                    padding: '8px 10px',
                  }}
                >
                  Last Login Date
                </Grid>
                <Grid
                  item
                  xs={6}
                  paddingLeft="12px"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {userInfo.lastLoginDate
                    ? moment(userInfo.lastLoginDate).format('M/D/yy, h:mm a')
                    : 'n/a'}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={8}
                sx={{
                  borderTop: '1px solid #e9ebef',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: '#f8f9fb',
                    padding: '8px 10px',
                  }}
                >
                  Created Date
                </Grid>
                <Grid
                  item
                  xs={6}
                  paddingLeft="12px"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {userInfo.createdDate
                    ? moment(userInfo.createdDate).format('M/D/yy, h:mm a')
                    : 'n/a'}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={8}
                sx={{
                  borderTop: '1px solid #e9ebef',
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: '#f8f9fb',
                    padding: '8px 10px',
                  }}
                >
                  Total logins
                </Grid>
                <Grid
                  item
                  xs={6}
                  paddingLeft="12px"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {userInfo.totalLogins}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </form>

      <ModalCustom
        title={'Are you sure you want to delete this User?'}
        modal={modal}
        subTittle={
          'Deleting this user will delete all related emails, events, and notes tied to this user. '
        }
        handleClose={() => {
          setModal(false);
        }}
        groupButton={
          <>
            <Button
              onClick={() => {
                setModal(false);
              }}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                width: '97px',
                height: '40px',
                lineHeight: '20px',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitDelete}
              variant="outlined"
              sx={{
                color: '#B3261E',
                fontWeight: 700,
                fontSize: '14px',
                width: '97px',
                height: '40px',
                display: user_id ? 'flex' : 'none',
                lineHeight: '20px',
                border: '1px solid #213B54',
              }}
            >
              Delete
            </Button>
          </>
        }
      />

      <ModalCustom
        title={'Are you sure you want to deactivate this User?'}
        modal={active.modalActive}
        subTittle={
          'Deactivating this user will remove ability to login to the application.'
        }
        handleClose={handleClose}
        groupButton={
          <>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '20px',
                width: '97px',
                height: '40px',
                border: '1px solid #213B54',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitActive}
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                width: '97px',
                height: '40px',
                lineHeight: '20px',
              }}
            >
              Save
            </Button>
          </>
        }
      />
    </>
  );
};
export default DetailFormUser;
