/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getAdminDashboardStats,
  getConversations,
} from 'data/api/intercom.api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface Tag {
  id: string;
  name: string;
}
export enum ConversationPartTypeEnum {
  admin = 'admin',
  user = 'user',
  bot = 'bot',
}
export interface ConversationPartModel {
  conversationPartId: string;
  conversationType?: string;
  conversationPartType?: string;
  conversationAuthorType?: string;
  conversationPartIntercomId?: string;
  deliveredAs?: string;
  subject?: string;
  body: string;
  conversationPartAuthor?: string;
  url?: string;
  conversationId?: string;
  createdAt?: number;
  updatedAt?: string;
  notifiedAt?: string;
  conversationAssignedTo?: string;
  conversationPartExternalId?: string;
  redacted?: boolean;
  authorName?: string;
  avatar?: string;
}

export interface IConverstation {
  conversationId: string;
  conversationType?: string;
  conversationIntercomId?: string;
  conversationPart?: ConversationPartModel[];
  tags?: Tag[];
}

export interface EnrollMember {
  id: string;
  companyId: string;
  enrolledMember?: number;
  createdDate?: Date;
}
export interface UniqueMemberEngagement {
  id: string;
  companyId: string;
  conversationPartTypeUserCount?: number;
  conversationPartTypeAdminCount?: number;
  uniqueMemberEngagement?: number;
  createdDate?: Date;
}
export interface MessageResponseRate {
  id: string;
  companyId: string;
  countConversationsRecordUser?: number;
  countConversationsRecord?: number;
  messageResponseRate?: number;
  createdDate?: Date;
}

export interface IDashboard {
  enrollMemberData: {
    enrollMemberValue?: number;
    enrollMembers?: EnrollMember[];
  };
  uniqueMemberData: {
    uniqueMemberValue?: number;
    countConversationsRecordUser?: number;
    countConversationsRecord?: number;
    uniqueMemberEngagements?: UniqueMemberEngagement[];
  };
  messageResponseData: {
    messageResponseRateValue?: number;
    conversationPartTypeUserCount?: number;
    conversationPartTypeAdminCount?: number;
    messageResponseRates?: MessageResponseRate[];
  };
}
export interface IIntercomState {
  getConversations: 'idle' | 'loading' | 'success' | 'failed';
  converstation: IConverstation | null;
  loading: boolean;
  getAdminDashboardStats: 'idle' | 'loading' | 'success' | 'failed';
  dashboardStats: IDashboard | null;
  loadingStats: boolean;
}

export const getConverstationAsync = createAsyncThunk(
  'intercom/getRelationshipsAsync',
  async (userId: string): Promise<any> => {
    const response = await getConversations(userId);
    return response.conversation;
  },
);

export const getAdminDashboardStatsAsync = createAsyncThunk(
  'intercom/getAdminDashboardStatsAsync',
  async (companyId: string): Promise<IDashboard> => {
    const response = await getAdminDashboardStats(companyId);
    return response;
  },
);

const initialState: IIntercomState = {
  getConversations: 'idle',
  converstation: null,
  loading: false,
  dashboardStats: null,
  getAdminDashboardStats: 'idle',
  loadingStats: false,
};

export const intercomSlice = createSlice({
  name: 'intercom',
  initialState,
  reducers: {
    handlecConverstaion: (state, action) => {
      state.converstation = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getConverstationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getConverstationAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.converstation = action.payload;
          state.loading = false;
        },
      )
      .addCase(getConverstationAsync.rejected, (state) => {
        state.loading = false;
        state.converstation = null;
      })
      .addCase(getAdminDashboardStatsAsync.pending, (state) => {
        state.loadingStats = true;
      })
      .addCase(
        getAdminDashboardStatsAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.dashboardStats = action.payload;
          state.loadingStats = false;
        },
      )
      .addCase(getAdminDashboardStatsAsync.rejected, (state) => {
        state.loadingStats = false;
        state.dashboardStats = null;
      });
  },
});
export const { handlecConverstaion } = intercomSlice.actions;
export default intercomSlice.reducer;
