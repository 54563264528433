import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export const ContentParser = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        a: {
          color: 'inherit',
        },
      }}
    >
      {parse(DOMPurify.sanitize(value))}
    </Box>
  );
};

interface IMessageFromProps {
  body: any;
  time: string;
  avatar: any;
  authorName: string;
}

export const MessageFrom = ({
  body,
  time,
  avatar,
  authorName,
}: IMessageFromProps) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'end'} padding={'8px'}>
      {avatar ? (
        <img
          src={avatar}
          className="h-[23px] w-[23px]"
          style={{
            borderRadius: '50%',
          }}
        />
      ) : (
        <Avatar
          sx={{
            color: 'white',
            fontSize: '13px',
            backgroundColor: '#213B54',
            fontWeight: 600,
          }}
          className="!h-[23px] !w-[23px]"
        >
          {authorName?.trim()?.charAt(0) || ''}
        </Avatar>
      )}
      <Stack
        sx={{
          backgroundColor: '#FAF7F4',
          padding: '12px',
          width: 'fit-content',
          textAlign: 'right',
          maxWidth: '80%',
          borderRadius: '15px',
          boxShadow: '0px 2px 8px 0px #00000026',
        }}
        spacing={1}
      >
        <Typography
          sx={{
            lineHeight: '18px',
            textAlign: 'left',
            fontSize: '14px',
            fontWeight: 400,
            color: '#000000',
            wordWrap: 'break-word',
          }}
        >
          <ContentParser value={body || ''} />
        </Typography>
        <Typography
          sx={{
            textAlign: 'start',
            fontSize: '12px',
            fontWeight: 500,
            color: '#7D818C',
          }}
        >
          {time}
        </Typography>
      </Stack>
    </Stack>
  );
};
