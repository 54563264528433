import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';
import {
  AllLossEventsPagination,
  EventInstance,
  EventQuestion,
} from '../models/loss-event.model';

export const getAllLossEventsPagination = async (
  page: number,
): Promise<AllLossEventsPagination> => {
  const data: AxiosResponse<AllLossEventsPagination> = await instance
    .get(API_ROUTES.adminEventsInstances + `?page=${page}`)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getEventInstance = async (
  eventInstanceId: string,
): Promise<[EventInstance, EventQuestion[]]> => {
  const data: AxiosResponse<{
    eventInstance: EventInstance;
    eventQuestions: EventQuestion[];
  }> = await instance
    .get(API_ROUTES.adminEventsInstance(eventInstanceId))
    .catch((error) => {
      throw error;
    });

  const { eventInstance, eventQuestions } = data.data;

  return [eventInstance, eventQuestions];
};

export const getEventInstancesForUser = async (
  userId: string,
): Promise<EventInstance[]> => {
  const data: AxiosResponse<{ eventInstances: EventInstance[] }> =
    await instance
      .get(API_ROUTES.adminEventsInstances + `?userId=${userId}`)
      .catch((error) => {
        throw error;
      });

  return data.data.eventInstances;
};

export const getUserEventsInstancesForUser = async (
  userId: string,
): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .get(API_ROUTES.getUserEvents(userId))
    .catch((error) => {
      throw error;
    });

  return data?.data;
};

export const getMigrationEvents = async (): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .get(API_ROUTES.migrationEvents)
    .catch((error) => {
      throw error;
    });

  return data?.data;
};

export const getTaskInfo = async (userEventId: string): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .get(`${API_ROUTES.migrationTaskInfo}?userEventId=${userEventId}`)
    .catch((error) => {
      throw error;
    });

  return data?.data;
};

export const postMigrationEvents = async (request: any): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .post(API_ROUTES.migrationEvents, request)
    .catch((error) => {
      throw error;
    });

  return data?.data;
};

export const deleteUserEvent = async (userEventId: any) => {
  const data: AxiosResponse<any> = await instance
    .delete(API_ROUTES.deleteUserEvent + `?userEventId=${userEventId}`)
    .catch((error) => {
      throw error;
    });

  return data.data;
};
